var SyntaxHighlighter = require('../../lib/syntaxhighlighter/js/shCore').SyntaxHighlighter;
require('../../lib/syntaxhighlighter/js/shBrushCss');
require('../../lib/syntaxhighlighter/js/shBrushJava');
require('../../lib/syntaxhighlighter/js/shBrushJScript');
require('../../lib/syntaxhighlighter/js/shBrushXml');
require('../../lib/syntaxhighlighter/js/shBrushPhp');
var Clipboard = require('../../lib/clipboardjs/clipboard.min');

$(document).ocmsDynamicLoadFinished(function() {
    $('.devDocument pre[data-brush]').each(function() {
        $(this).addClass('brush: ' + this.dataset.brush).removeAttr('data-brush');
    });
    initSyntaxHighlighters();
});

function initSyntaxHighlighters() {
    SyntaxHighlighter.defaults.toolbar = false;
    SyntaxHighlighter.highlight();
    $('.syntaxhighlighter').prepend(
        '<div class="highlighterButtons"><button class="viewSource">View Source</button><button class="copyButton">Copy</button></div>'
    );

    new Clipboard('.syntaxhighlighter .copyButton', {
        text: function (element) {
            var codeContainer = $(element).closest('.syntaxhighlighter').find('.code');
            var text = '';

            $('.line', codeContainer).each(function () {
                text += $(this).text() + '\n';
            });

            return text;
        }
    });

    $('.syntaxhighlighter .viewSource').click(function (evt) {
        evt.preventDefault();

        var highlighterContainer = $(evt.target).parents('.syntaxhighlighter');

        var text = '';

        $('.code .line', highlighterContainer).each(function () {
            text += $(this).text() + '\n';
        });

        var newWindow = window.open(
            '',
            '_blank',
            'width=750, height=400, '
            + 'left=' + ((screen.width - 750) / 2) + ', top=' + ((screen.height - 400) / 2) + ', '
            + 'location=0, resizable=1, menubar=0, scrollbars=1'
        );

        newWindow.document.write(
            '<pre>'
            + text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
            + '</pre>'
        );
    });
}
